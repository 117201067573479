import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Icon from "./Icon"
import getIcon from "../utils/get-icon"


function Social() {
  return (
    <StaticQuery
      query={socialQuery}
      render={data => {
        const { social } = data.site.siteMetadata
        return (
          <Container>
              <li>
                <a href={social.twitter}><Icon name='twitter' icon={getIcon('twitter')} /></a>
              </li>
              <li>
                <a href={social.facebook}><Icon name='facebook' icon={getIcon('facebook')} /></a>
              </li>
              <li>
                <a href={social.linkedin}><Icon name='linkedin' icon={getIcon('linkedin')} /></a>
              </li>
              <li>
                <a href={social.github}><Icon name='github' icon={getIcon('github')} /></a>
              </li>
              <li>
                <a href={social.nuget}><Icon name='nuget' icon={getIcon('nuget')} /></a>
              </li>
              <li>
                <a href={social.qrz}><Icon name='qrz' icon={getIcon('qrz')} /></a>
              </li>
              <li>
                <a rel="me" href={social.mastodon}><Icon name='mastodon' icon={getIcon('mastodon')} /></a>
              </li>
          </Container>
        )
      }}
    />
  )
}

const socialQuery = graphql`
  query SocialQuery {
    site {
      siteMetadata {      
        social {
          twitter
          facebook
          linkedin
          github
          nuget
          qrz
          mastodon
        }
      }
    }
  }
`

const Container = styled.div`
  display: flex;
  list-style: none;
  li {
    padding: 0;
    margin: .25rem;
    display: flex;
    align-content: center;
    -webkit-box-align: center;
    align-items: center;
    justify-content: center;
    height: 2.1875rem;
    width: 2.1875rem;
    line-height: 2.1875rem;
    border-radius: 50%;
    text-align: center;
    border: 1px solid #ebebeb;
  }
  a { 
    color: black;
    text-decoration: none;
    box-shadow: 0 0 0 0;
  }
`

export default Social
